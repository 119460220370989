import React from "react";
import { Switch } from "react-router-dom";
import Home from "./containers/Home/Home"
import Signup from "./containers/SignUp/SignUp"
import Login from "./containers/Login/Login"
import Admin from "./containers/Admin/Admin"
import Users from "./containers/Users/Users"
import Properties from "./containers/Properties/Properties"
import Sellerfinancing from "./containers/Sellerfinancing/Sellerfinancing"
import AuthenticatedRoute from "./components/Routes/AuthenticatedRoute"
import UnauthenticatedRoute from "./components/Routes/UnauthenticatedRoute"


const Routes = ({ childProps }) => (
  <Switch>
    <UnauthenticatedRoute path="/" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <AuthenticatedRoute path="/admin" exact component={Admin} props={childProps} />
    <AuthenticatedRoute path="/users/:userid?" component={Users} props={childProps} />
    <AuthenticatedRoute path="/users" exact component={Users} props={childProps} />
    <AuthenticatedRoute path="/properties" exact component={Properties} props={childProps} />
    <AuthenticatedRoute path="/sellerfinancing" exact component={Sellerfinancing} props={childProps} />
  </Switch>

);

export default Routes;