import React, {useState} from 'react';
import {AppBar, Toolbar, Button, List, ListItem, Divider, Avatar} from "react95"
import {Link} from "react-router-dom"
import {Auth} from "aws-amplify"




const logout = async(setAuthenticated, setUser) =>{
  await Auth.signOut()
  setAuthenticated(false)
  setUser(null)
}


const NavBar = ({authenticated, user, setAuthenticated, setUser}) => {
  const [ open, setOpen ] = useState(false);
  return ( 
    <AppBar style={{ zIndex: 1 }}>
      { authenticated && (
      <Toolbar style={{ justifyContent: 'space-between' }}>

       
        <>
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <Button
              onClick={() => setOpen(!open)}
              style={{ fontWeight: 'bold' }}
            >
              menu
            </Button>
            {open && (
            <List
              style={{
                position: 'absolute',
                left: '0',
                top: '100%'
              }}
              onClick={() => setOpen(false)}
            >
              <ListItem>
                <Link to="/users">
                  <span role='img' aria-label='👨‍💻'>
                    👨‍💻
                  </span>
                  Users
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/sellerfinancing">
                  <span role='img' aria-label='👨‍💻'>
                    🏦
                  </span>
                  Financing
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/properties">
                  <span role='img' aria-label='👨‍💻'>
                    🏠
                  </span>
                  Properties
                </Link>
              </ListItem>
              <Divider />
              <ListItem
                disabled={!authenticated}
                onClick={() => logout(setAuthenticated, setUser)}
              >
                <span role='img' aria-label='🔙'>
                  🔙
                </span>
                Logout
              </ListItem>
            </List>
          )}
          </div>
          <Avatar size={50} style={{ background: 'palevioletred' }}>
            {user && `${user.firstName.charAt(0)} ${user.lastName.charAt(0)}`}
          </Avatar>
        </>
       
      </Toolbar>
      )}
    </AppBar>
   );
}
 
export default NavBar;