import React , { useEffect, useState } from 'react';
import {  TextField, Button, Window, WindowContent, WindowHeader } from 'react95';
import {createNewUser} from "../../services/signUp.js"




const SignUp = () => {

  const [ formData, setForm ] = useState({});

  useEffect(() => {
    console.log(formData)
  }, [formData])

 
  return (
    <Window style={{display:'flex', flexDirection:'column', marginStart:"auto", width:"fit-content", marginRight:"auto", marginTop:"100px", marginLeft:"auto"}}>
      <WindowHeader className='window-header'>
        Fabrica Admin Tools - Create Account
      </WindowHeader>
      <WindowContent>
           
        <div style={{ width: 400 }}>
          <div style={{ display: 'flex', flexDirection:'column' }}>
            <p style={{marginBottom:"15px"}}>@fabrica.land email address req'd</p>
            <TextField
              placeholder='first name'
              onChange={e => setForm({...formData, ...{firstName:e.target.value}})}
              fullWidth
            />
            <TextField
              placeholder='last name'
              onChange={e => setForm({...formData, ...{lastName:e.target.value}})}
              fullWidth
              style={{marginTop:"10px", marginBottom:"10px"}}
            />
            <TextField
              type="email"
              placeholder='email'
              onChange={e => setForm({...formData, ...{email:e.target.value}})}
              fullWidth
              style={{marginTop:"10px", marginBottom:"10px"}}
            />
            <TextField
              type='password'
              placeholder='password'
              onChange={e => setForm({...formData, ...{password:e.target.value}})}
              fullWidth
            />
            <Button style={{marginTop:"10px"}} onClick={() => createNewUser(formData)}>Create Account</Button>
          </div>
        </div>
      </WindowContent>
    </Window> 
  );
}
 
export default SignUp;