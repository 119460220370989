import { Auth } from "aws-amplify";

const awsconfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  API: {
    endpoints: [
      {
        name: "fabrica-admin",
        endpoint: process.env.REACT_APP_FABRICA_API,
        custom_header: async () => {
          try {
            const authUser = await Auth.currentSession();
            return { Authorization: authUser.idToken.jwtToken };
          } catch {
            return null;
          }
        },
      },
    ],
  }
};

export default awsconfig;