import React from "react";
import { Route, Redirect } from "react-router-dom";

export default ({ component: C, props: cProps, ...rest }) => {  
  return(
    <Route
      computedMatch={rest.computedMatch}
      location={rest.location}
      path={rest.path}
      render={() =>
      cProps.authenticated ? (
        <C authenticated={cProps.authenticated} user={cProps.user} setAuthenticated={cProps.setAuthenticated} />
      ) : (
        <Redirect
          to="/login"
        />
      )}
    />
)};