/* eslint-disable import/prefer-default-export */
 import { Auth } from "aws-amplify";

 
 export const login = async (data,setAuthenticated, setUser) => {
  try {
      const {attributes :{ 'custom:first_name': firstName, 'custom:last_name': lastName, email}} = await Auth.signIn(data.email, data.password)
      setUser({firstName, lastName,email})
      setAuthenticated(true)
  } catch (e) {
    console.log(e)
  }
};
