import React, {useEffect, useState} from 'react';
import { Window, WindowHeader, Button, Hourglass, WindowContent,Table,TableRow, TableHead,TableHeadCell, TableBody, TableDataCell, Anchor} from 'react95';
import {getProperties} from "../../services/properties"
import GlobalStyle from "../../styles/globalStyle"

const Properties = () => {


const [properties, setProperties] = useState([])
const [key, setKey] = useState([])
const [page, setPage] = useState(-1)
const [loading, setLoading] = useState(false)
const [queryAction, setQueryAction] = useState("next")
const [more, setMore] = useState(true)


const fetchProperties = async(queryKey) => {
  setLoading(true)
  const {properties: curProperties, nextKey, count} = await getProperties(queryKey)
  setProperties(curProperties) 
  if(queryAction === "next" && key.length === (page+1) && nextKey) setKey([...key,nextKey])
  setMore(!!nextKey)
  if(queryAction==="next" && nextKey) setPage(page+1) 
  setQueryAction(null)
  setLoading(false)
}


useEffect(() => {
  fetchProperties()

}, [])

useEffect(() => {
  if(queryAction === "next") {
    fetchProperties(key[page])
  } else if (queryAction === "prev"){
    fetchProperties(page-1 === 0 ? null : key[page-1])
    setPage(page - 1)
  }
}, [queryAction])

if (loading) return (
  <div style={{display:'flex', flexDirection:'column', marginStart:"auto", width:"fit-content", marginRight:"auto", marginTop:"100px", marginLeft:"auto"}}>
    <Hourglass />
  </div>
)

  return ( 
    <div>
      <GlobalStyle />
      <Window style={{ fontFamily:"ms_sans_serif" ,display:'flex', flexDirection:'column', marginStart:"auto", width:"fit-content", marginRight:"auto", marginTop:"100px", marginLeft:"auto"}}>
        <WindowHeader>Properties</WindowHeader>

        <WindowContent>

          <Table>
            <TableHead>
              <TableRow head>
                <TableHeadCell>Property Name</TableHeadCell>
                <TableHeadCell>Docs</TableHeadCell>
                <TableHeadCell>Status</TableHeadCell>
                <TableHeadCell>Owner</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {properties && properties.map(property => property.propertyName && (

              <TableRow>
                <TableDataCell>
                  <Anchor href={`${process.env.REACT_APP_FABRICA_DOMAIN}/property/${property.propertyId}`} target='_blank'>
                    {property.propertyName}
                  </Anchor>
                </TableDataCell>
                <TableDataCell>
                  {property.valid ? <span role="img" aria-label='✅ '> ✅ </span>: <span role="img" aria-label='🛑'> 🛑</span>}
                </TableDataCell>
                <TableDataCell>
                  {property.saleStatus.forSale ? "For Sale":
         property.saleStatus.sellerFinancing ? "Seller Financing":
         property.saleStatus.offMarket ? "Off Market" : 
         "no status"}
                </TableDataCell>
                <TableDataCell>
                  <Anchor href={`/users/${property.ownerId}`}>
                    {property.ownerName}
                  </Anchor>

                </TableDataCell>


              </TableRow>
              ))}
            </TableBody>
          </Table>
          <div>
            {page > 0 && (
            <Button onClick={()=> setQueryAction("prev")}>
              BACK
            </Button>
              )}
            { more && (
              <Button onClick={()=> setQueryAction("next")}>
                NEXT
              </Button>

            )}
            
            {`Page ${page + 1}`}
          </div>
        </WindowContent>
      </Window>
    </div>
   );
}
 
export default Properties;