import {API} from "aws-amplify"

export const getSellerFinancing = async () => {
  try {
    const apiName = "fabrica-admin";
    const path = `/sellerfinancing`;
    const req = {body:{}}
    return API.post(apiName, path, req);
  } catch (e) {
    throw e;
  }
};