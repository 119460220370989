import React from "react";
import { Route, Redirect } from "react-router-dom";

export default ({ component: C, props: cProps, ...rest}) => {
  console.log(rest.path)
  console.log(cProps.authenticated)
 return (
   <Route
     computedMatch={rest.computedMatch}
     location={rest.location}
     path={rest.path}
     render={() =>
      !cProps.authenticated ? (
        <C autheticated={cProps.authenticated} user={cProps.user} setAuthenticated={cProps.setAuthenticated} setUser={cProps.setUser} />
      ) : (
        <Redirect
          to="/admin"
        />
      )}
   />
  )
}
