import {API} from "aws-amplify" 

export const getProperties = async (curKey) => {
  try {
    const apiName = "fabrica-admin";
    const path = `/properties`;
    const req = {body:{curKey}}
    return API.post(apiName, path, req);
  } catch (e) {
    throw e;
  }
};