import React,  {useEffect, useState}  from 'react';
import { withRouter, useParams } from 'react-router-dom';

import { 
         Window, 
         WindowHeader, 
         WindowContent, 
         TextField,
          Button, 
          Hourglass, 
          Fieldset, 
          Anchor,
          Table,
          TableHead,
          TableHeadCell,
          TableRow,
          TableBody,
          TableDataCell } from 'react95';

import GlobalStyle from "../../styles/globalStyle"
import {getUser} from "../../services/users"

const Users = () => {


  const [userId, setUserId] = useState("")
  const [activeUser, setActiveUser] = useState()
  const [isLoading, setIsLoading] = useState()
  const [query, setQuery] = useState(false)
  const {userid: userParam} = useParams();

  const fetchData = async(userQuery)=>{
    setIsLoading(true)
    const result = await getUser(userQuery || userId)
    setActiveUser(result)
    setQuery(false)
    setIsLoading(false)
  }

  useEffect(() =>{
    if(userParam){   
      setUserId(userParam)
      fetchData(userParam)
    }
  }, [])   // eslint-disable-next-line react-hooks/exhaustive-deps



   useEffect(() => {
     if(query){
        fetchData()
     }
   
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps



  return (
    <div>
      <GlobalStyle />
      <Window style={{ fontFamily:"ms_sans_serif" ,display:'flex', flexDirection:'column', marginStart:"auto", width:"fit-content", marginRight:"auto", marginTop:"100px", marginLeft:"auto"}}>
        <WindowHeader className='window-header'>
          Search for User
        </WindowHeader>
        <WindowContent>
         
          <div style={{ width: 400 }}>
            <TextField
              type="userid"
              placeholder='search with user id'
              onChange={e => setUserId(e.target.value)}
              value={userId}
              fullWidth
              style={{marginTop:"10px", marginBottom:"10px"}}
            />
            <Button onClick={()=> setQuery(true)}>Search</Button>
          </div>
        </WindowContent>
      </Window>
      {isLoading ? (

        <div style={{display:'flex', flexDirection:'column', marginStart:"auto", width:"fit-content", marginRight:"auto", marginTop:"100px", marginLeft:"auto"}}>
          <Hourglass />
        </div>

    ) : (

      <>

        <Window style={{ fontFamily:"ms_sans_serif" ,display:'flex', flexDirection:'column', marginStart:"auto", width:"fit-content", marginRight:"auto", marginTop:"25px", marginLeft:"auto"}}>
          <WindowHeader className='window-header'>
            User Info
          </WindowHeader>
          <WindowContent style={{width:400}}>
            { activeUser && activeUser.success ? (
              <div>

                <Fieldset label="User Name">
                  {activeUser.user.completeName}
                </Fieldset>
                <Fieldset label="Wallet Address" style={{marginTop:"25px"}}>
                  <Anchor href={`${process.env.REACT_APP_ENV==='Prod'? "https://etherscan.io": "https://rinkeby.etherscan.io"}/address/${activeUser.user.walletAddress}`} target="_blank">{activeUser.user.walletAddress}</Anchor>
                </Fieldset>
                <Fieldset label="ETH Balance" style={{marginTop:"25px"}}>
                  {activeUser.userEthBalance}
                </Fieldset>
                <Fieldset label="Sila Balance" style={{marginTop:"25px"}}>
                  {activeUser.userSilaBalance}
                </Fieldset>
                <Fieldset label="KYC Status" style={{marginTop:"25px"}}>
                  {
            activeUser.user.kycStatus === 0 ? "Not Submitted" 
            : activeUser.user.kycStatus === 1 ? "Pending"
            : activeUser.user.kycStatus === 2 ? "Approved" 
            : "No Data"
          }
                </Fieldset>
                <Fieldset label="Bank Account" style={{marginTop:"25px"}}>
                  {
            activeUser.user.linkedBank ? `${activeUser.user.linkedBank} *${activeUser.user.linkedBankMetadata.account.mask}`
            : "Bank Account not linked"
          }
                </Fieldset>
                <Fieldset label="Address" style={{marginTop:"25px"}}>
                  {
            activeUser.user.address ? `${activeUser.user.address.streetAddress}, ${activeUser.user.address.city}, ${activeUser.user.address.state} ${activeUser.user.address.zip} ` 
            : "Address not available"
          }
                </Fieldset>
              </div>
      ) : (
        <p>No User</p>
      )}
          </WindowContent>
        </Window>
        <div>
          {activeUser && activeUser.success && (
          <Window style={{ fontFamily:"ms_sans_serif" ,display:'flex', flexDirection:'column', marginStart:"auto", width:"fit-content", marginRight:"auto", marginTop:"25px", marginLeft:"auto"}}>
            <WindowHeader>
              User Properties (
              {activeUser.properties.length}
              )
            </WindowHeader>

            <WindowContent>
              <Table>
                <TableHead>
                  <TableRow head>
                    <TableHeadCell>Property Name</TableHeadCell>
                    <TableHeadCell>County</TableHeadCell>
                    <TableHeadCell>Docs</TableHeadCell>
                    <TableHeadCell>Status</TableHeadCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeUser.properties.length > 0 && activeUser.properties.map(property => (
                    <TableRow key={property.token.tokenId}>
                      <TableDataCell>
                        <Anchor href={`${process.env.REACT_APP_FABRICA_DOMAIN}/property/${property.token.tokenId}`} target='_blank'>
                          {property.metadata.name}
                        </Anchor>
                      </TableDataCell>
                      <TableDataCell>
                        
                        {property.metadata.county ? property.metadata.county.name : "no county"}
                        
                      </TableDataCell>
                      <TableDataCell>
                        {property.valid ? <span role="img" aria-label='✅ '> ✅ </span>: <span role="img" aria-label='🛑'> 🛑</span>}
                      </TableDataCell>
                      <TableDataCell>
                        {property.saleStatus.forSale ? "For Sale":
                     property.saleStatus.sellerFinancing ? "Seller Financing":
                     property.saleStatus.offMarket ? "Off Market" : 
                     "no status"}
                      </TableDataCell>
                    </TableRow>
        ))}
                </TableBody>
              </Table>
            </WindowContent>
          </Window>
      )}

        </div>
      </>



    )}
 
    </div>

  )

}
 
export default withRouter(Users);