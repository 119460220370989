import React, {useState} from 'react';

import {  TextField, Button, Window, WindowContent, WindowHeader,Anchor } from 'react95';
import {login} from "../../services/login"

const Login = ({setAuthenticated, setUser}) => {
  const [ formData, setForm ] = useState({});



  return ( 
    <Window style={{display:'flex', flexDirection:'column', marginStart:"auto", width:"fit-content", marginRight:"auto", marginTop:"100px", marginLeft:"auto"}}>
      <WindowHeader className='window-header'>
        Fabrica Admin Tools - Login
      </WindowHeader>
      <WindowContent>
         
        <div style={{ width: 400 , marginBottom:"25px"}}>
          <TextField
            type="email"
            placeholder='email'
            onChange={e => setForm({...formData, ...{email:e.target.value}})}
            fullWidth
            style={{marginTop:"10px", marginBottom:"10px"}}
          />
          <TextField
            type='password'
            placeholder='password'
            onChange={e => setForm({...formData, ...{password:e.target.value}})}
            fullWidth
          />
          <Button style={{marginTop:"10px"}} onClick={() => login(formData, setAuthenticated, setUser)}>Login</Button>
        </div>
        <Anchor href="/signup">Or Sign Up</Anchor>
      </WindowContent>
    </Window>
   );
}
 
export default Login;
