import {API} from "aws-amplify"

export const getUser = async (userId) => {
  try {
    const apiName = "fabrica-admin";
    const path = `/users`;
    const req = {body:{userId}}
    return API.post(apiName, path, req);
  } catch (e) {
    throw e;
  }
};