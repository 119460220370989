 import { Auth } from "aws-amplify";

 
 export const createNewUser = async (user) => {
  try {
    console.log("calling signup")
    const authResult = await Auth.signUp({
      username: user.email,
      password: user.password,
      attributes: {
        "custom:first_name": user.firstName,
        "custom:last_name": user.lastName,
        email: user.email,
      },
    });

    console.log(authResult)
    const currUser = await Auth.signIn(user.email, user.password);
    console.log(currUser)
    return Auth.verifyUserAttribute(currUser, "email");
  } catch (e) {
    throw e;
  }
};
