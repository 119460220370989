import React from 'react';
import {  Anchor, ListItem, List } from 'react95';
import GlobalStyle from "../../styles/globalStyle"

const Home = () => {
  return (
    <div style={{fontFamily:"ms_sans_serif" ,display:'flex', flexDirection:'column', marginStart:"auto", width:"fit-content", marginRight:"auto", marginTop:"100px", marginLeft:"auto"}}>
      <GlobalStyle />
      <h1 style={{marginBottom:"50px"}}>Fabric Admin Tools</h1>
      <List>
        <ListItem> 
          {' '}
          <Anchor href='/signup'> Create Account</Anchor>
        </ListItem>
        <ListItem><Anchor href="/login">Login</Anchor></ListItem>        
      </List>
       
    </div>
  )
}
 
export default Home;

