import React , {useEffect, useState} from 'react';
import { ThemeProvider } from 'styled-components';
import {Auth,} from "aws-amplify";
import original from "react95/dist/themes/original";
import {Hourglass} from "react95"
import {withRouter} from "react-router-dom"
import NavBar from "./components/NavBar/NavBar"
// pick a theme of your choice
import GlobalStyle from "./styles/globalStyle"
// original Windows95 font (optionally)

import Routes from "./Routes"

function App() {


  const [authenticated, setAuthenticated] = useState( )
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)
  const getUser = async () =>{

    try {
      const {attributes :{ 'custom:first_name': firstName, 'custom:last_name': lastName, email}} = await Auth.currentAuthenticatedUser()
      setAuthenticated(true)
      setUser({firstName,lastName,email})
      setLoading(false)
    
    } catch (error) {
      setAuthenticated(false)
      setLoading(false)
    }
  
  }

  

  useEffect(() => {
      setLoading(true)
      getUser()
  
  }, [])


  return (
    <div>
      <GlobalStyle />
      <ThemeProvider theme={original}>
        <NavBar authenticated={authenticated} user={user} setAuthenticated={setAuthenticated} setUser={setUser}  />
        {loading ? (
          <div style={{display:'flex', flexDirection:'column', marginStart:"auto", width:"fit-content", marginRight:"auto", marginTop:"100px", marginLeft:"auto"}}>
            <Hourglass />
          </div>
        ):(
          <Routes childProps={{authenticated,user, setAuthenticated, setUser}} />
        )}
      </ThemeProvider>
    </div>
  );
}

export default withRouter(App);
