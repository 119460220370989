import React, {useEffect, useState} from 'react';
import {  Hourglass, Table, TableHead,TableRow,TableHeadCell,TableBody,TableDataCell,
WindowContent,
WindowHeader,
Window,
Anchor

} from 'react95';
import {getSellerFinancing} from "../../services/sellerFinancing"
import {formatDate} from "../../utils/utils"
import GlobalStyle from "../../styles/globalStyle"

const Sellerfinancing = () => {

    const [ sellerFinancingData, setSellerFinancingData ] = useState();
    const [isLoading, setIsLoading] = useState(true);


    const fetchData = async() =>{
        const plans = await getSellerFinancing()
        setSellerFinancingData(plans);
        setIsLoading(false)
    }


    useEffect(() => {
        fetchData()
  }, [])




  if(isLoading){
    return (
      <div style={{display:'flex', flexDirection:'column', marginStart:"auto", width:"fit-content", marginRight:"auto", marginTop:"100px", marginLeft:"auto"}}>
        <Hourglass />
      </div>
    )
  } 
    console.log(sellerFinancingData)
 return ( 
   <div>
     <GlobalStyle />
     <Window style={{ fontFamily:"ms_sans_serif" ,display:'flex', flexDirection:'column', marginStart:"auto", width:"fit-content", marginRight:"auto", marginTop:"100px", marginLeft:"auto"}}>
       <WindowHeader>Seller Financing</WindowHeader>

       <WindowContent>

         <Table>
           <TableHead>
             <TableRow head>
               <TableHeadCell>Property Name</TableHeadCell>
               <TableHeadCell>Seller</TableHeadCell>
               <TableHeadCell>Buyer</TableHeadCell>
               <TableHeadCell>Last Payment Date</TableHeadCell>
               <TableHeadCell>Last Payment Status</TableHeadCell>
               <TableHeadCell>Final Payment Date</TableHeadCell>
             </TableRow>
           </TableHead>
           <TableBody>
             {sellerFinancingData.map(property => property.propertyName && (
             <TableRow>
               <TableDataCell>
                 <Anchor href={`${process.env.REACT_APP_FABRICA_DOMAIN}/property/${property.propertyId}`} target='_blank'>
                   {property.propertyName}
                 </Anchor>
               </TableDataCell>
               <TableDataCell>
                 <Anchor href={`/users/${property.sellerId}`}>
                   {property.sellerName}
                 </Anchor>
               </TableDataCell>
               <TableDataCell>
                 <Anchor href={`/users/${property.buyerId}`}>
                   {property.buyerName}
                 </Anchor> 
               </TableDataCell>
               <TableDataCell>{formatDate(property.lastPaymentDate)}</TableDataCell>
               <TableDataCell>{property.lastPaymentStatus}</TableDataCell>
               <TableDataCell>{formatDate(property.finalExpectedPayment)}</TableDataCell>

             </TableRow>
      ))}
           </TableBody>
         </Table>
       </WindowContent>
     </Window>
   </div>

   );
  


 
}
 
export default Sellerfinancing;