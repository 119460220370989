import React from 'react';
import {  Anchor, ListItem, List } from 'react95';
import GlobalStyle from "../../styles/globalStyle"


const Admin  = () => {
  return (    
  
    <div style={{fontFamily:"ms_sans_serif" ,display:'flex', flexDirection:'column', marginStart:"auto", width:"fit-content", marginRight:"auto", marginTop:"100px", marginLeft:"auto"}}>
      <GlobalStyle />
      <h1 style={{marginBottom:"50px"}}>Select Function Below</h1>
      <List>
        <ListItem> 
          {' '}
          <Anchor href='/users'>Users</Anchor>
        </ListItem>
        <ListItem><Anchor href="/sellerfinancing">Seller Financing</Anchor></ListItem>   
        <ListItem><Anchor href="/properties">Properties</Anchor></ListItem>        
      </List>
       
    </div>
)
    ;
}
 
export default Admin;